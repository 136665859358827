import { writeFile, utils, WorkSheet, ColInfo } from 'xlsx'
import SheetHandler, { CellDataOption, sheetHandlerOption } from '@/lib/SheetHandler'
import CountryEnum from '@/enums/CountryEnum'
import { RecipientSkeletonFields } from '@/gateway/recipient/model/RecipientSkeletonFields'
import { ReceiveMethodEnum, RecipientFieldEnum } from '@/enums'
import { recipientFieldOrder } from '@/data/RecipientFieldOrder'
import i18n from '@/plugins/i18n'
import { getNoticeMaps } from '@/data/sheet/notice'
import { BankCell, MasterCode } from '@/gateway/sheet/MasterCode'
import { Utils } from '@/static/Utils'
import { staticValues } from '@/static/StaticValues'
import { CountryPhoneCodes } from '@/data/CountryPhoneCodes'

class RecipientSheetHandler extends SheetHandler {
  protected _sheetJson: any = {}
  private recipientFields = Object.keys(RecipientFieldEnum).filter(
    key => typeof RecipientFieldEnum[key as any] === 'number'
  )
  protected _sheetValidHeaders: Array<string> = this.recipientFields.map(field => field.toLowerCase())

  get sheetJson(): any {
    return this._sheetJson
  }
  set sheetJson(value: any) {
    this._sheetJson = value
  }

  get sheetValidHeaders(): Array<string> {
    return this._sheetValidHeaders
  }

  protected filterValidSheets(sheetNames: Array<string>): Array<string> {
    const validCountryList: Array<string> = Object.keys(CountryEnum).filter(
      key => typeof CountryEnum[key as any] === 'number'
    )
    return sheetNames.filter((sheetName: string) => {
      const countryWithMethod: Array<string> = sheetName.split('_')
      const country: string = countryWithMethod[0]
      const method: string = sheetName.includes(`${country}_`) ? sheetName.replace(`${country}_`, '') : ''
      const hasMethod = Utils.isValidEnumKey(method.toUpperCase(), ReceiveMethodEnum)
      return hasMethod && validCountryList.includes(country)
    })
  }

  private getRegisterColumnOptions(sheetName: string, sheetJson: Array<Dictionary<any>>): Array<ColInfo> {
    const isKoLocale = localStorage.getItem('locale') === 'ko'
    const noticeColumnInfo: Array<ColInfo> = isKoLocale
      ? [{ wch: 13 }, { wch: 13 }, { hidden: true }, { wch: 20 }, { wch: 4 }, { wch: 80 }]
      : [{ wch: 25 }, { wch: 18 }, { hidden: true }, { wch: 25 }, { wch: 4 }, { wch: 125 }]
    const bankListColumnInfo: Array<ColInfo> = isKoLocale
      ? [{ hidden: true }, { hidden: true }, { wch: 15 }, { wch: 60 }, { wch: 13 }, { wch: 55 }]
      : [{ hidden: true }, { hidden: true }, { wch: 15 }, { wch: 60 }, { wch: 13 }, { wch: 55 }]
    const columnInfos: Dictionary<Array<ColInfo>> = {
      NOTICE: noticeColumnInfo,
      BANK_LIST: bankListColumnInfo
    }
    return columnInfos[sheetName] || this.getColumnOptions(sheetJson)
  }

  public saveSheetByJson = (name = 'recipient.xlsx', options?: sheetHandlerOption) => {
    const workBook = utils.book_new()
    Object.keys(this.sheetJson).forEach(sheetName => {
      const eachSheetJson = this.sheetJson[sheetName]
      let eachSheet: WorkSheet = this.jsonToSheet(eachSheetJson)
      eachSheet['!cols'] = this.getRegisterColumnOptions(sheetName, eachSheetJson)

      const useCellDataOption = options?.useCellDataOption && !['NOTICE', 'BANK_LIST'].includes(sheetName)
      if (useCellDataOption) {
        const option: Dictionary<CellDataOption> = options?.cellDataOptions || {}
        eachSheet = this.setCellDataType(eachSheet, eachSheetJson, option)
        eachSheet['!ref'] = this.setRangeCellDataType(eachSheetJson)
      }
      utils.book_append_sheet(workBook, eachSheet, sheetName)
    })
    writeFile(workBook, name, { bookType: 'xlsx' })
  }

  public getFieldObject(form: RecipientSkeletonFields): Dictionary<string> {
    const fieldObject: Dictionary<string> = {}
    const fields = form.fields.sort((field, nextField) => {
      const fieldName = RecipientFieldEnum[field.id]
      const nextFieldName = RecipientFieldEnum[nextField.id]
      return recipientFieldOrder.indexOf(fieldName) - recipientFieldOrder.indexOf(nextFieldName)
    })
    const isoCodeOptionColumns = [RecipientFieldEnum.NATION, RecipientFieldEnum.PHONE_CODE]
    fields.forEach(field => {
      const fieldName = RecipientFieldEnum[field.id].toLowerCase()
      if (fieldName === 'phone') {
        const defaultString = i18n.t('commons.default')
        const phoneCodeNumber = CountryPhoneCodes.find(code => code.iso === form.country.toUpperCase())?.phone_code
        const phoneCodeSuffix = phoneCodeNumber
          ? `(${defaultString} : ${phoneCodeNumber})`
          : `(${defaultString} : ${form.country.toUpperCase() === 'CN_USD' ? '86' : form.country.toUpperCase()})`
        const phoneCodeColumn = i18n.t(`sheet.field.${fieldName}_code`)
        fieldObject[`${fieldName}_code`] = `${phoneCodeColumn} ${phoneCodeSuffix}`
        fieldObject[fieldName] = i18n.t(`sheet.field.${fieldName}_number`) as string
        return
      }
      if (fieldName.includes('address')) {
        const translated = i18n.t(`sheet.field.${fieldName.replace('address_', '')}`) as string
        fieldObject[fieldName] = translated
        return
      }
      const isoCode = isoCodeOptionColumns.includes(field.id) ? ' (ISO Code)' : ''
      const optional = field.optional ? ` (${i18n.t('commons.optional')})` : ''
      const tempTranslated: string = i18n.t(`sheet.field.${fieldName}`) as string
      const isUntranslated = tempTranslated.includes('sheet.field')
      const translatedFieldName = isUntranslated ? i18n.t(`sheet.field.bank_${fieldName}`) : tempTranslated
      const fieldNameWithOptional = `${translatedFieldName}${optional}${isoCode}`
      fieldObject[fieldName] = fieldNameWithOptional
    })
    return fieldObject
  }

  public makeSheetJson(
    recipientFields: Array<RecipientSkeletonFields>,
    receivableCurrencies: Dictionary<Array<string>>
  ): Dictionary<Array<any>> {
    const sheet: Dictionary<Array<any>> = {}
    recipientFields.sort((a, b) => {
      const aSheetName = `${a.country}_${ReceiveMethodEnum[a.receive_method]}`
      const bSheetName = `${b.country}_${ReceiveMethodEnum[b.receive_method]}`
      return aSheetName < bSheetName ? -1 : aSheetName > bSheetName ? 1 : 0
    })
    recipientFields.forEach((recipientField: RecipientSkeletonFields) => {
      const country = recipientField.country
      const receiveMethod = ReceiveMethodEnum[recipientField.receive_method]
      const sheetName = `${country}_${receiveMethod}`
      const corpsId = i18n.t('sheet.field.corps_id') as string
      const defaultCurrency: Array<string> = ['USD']
      const countryCurrencies: Array<string> = receivableCurrencies[country] || defaultCurrency
      const currencyString = i18n.t('sheet.field.currency')
      const defaultString = i18n.t('commons.default')
      const baseCurrency = `${currencyString} (${defaultString} : ${
        receiveMethod === 'USD_ANYWHERE' ?  'USD' :countryCurrencies.join(' / ')
      })`
      sheet[sheetName] = [
        Object.assign({ corps_id: corpsId }, this.getFieldObject(recipientField), { base_currency: baseCurrency })
      ]
    })
    return sheet
  }

  public setSheetJsonToExport(sheetJson: Dictionary<any>): void {
    const sheet: Dictionary<Array<Dictionary<string | number> | BankCell>> = {
      NOTICE: getNoticeMaps(),
      BANK_LIST: MasterCode.getBankList()
    }
    this.sheetJson = Object.assign(sheet, sheetJson)
  }

  public setSheetJsonToExportWithOthers(sheetJson: Dictionary<any>, otherCountries: Array<string>): void {
    const noticeCountries = [...otherCountries, ...staticValues.availableCountriesIso]
    const sheet: Dictionary<Array<Dictionary<string | number> | BankCell>> = {
      NOTICE: getNoticeMaps(noticeCountries),
      BANK_LIST: MasterCode.getBankList()
    }
    this.sheetJson = Object.assign(sheet, sheetJson)
  }
}

export default RecipientSheetHandler
