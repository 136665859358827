import i18n from '@/plugins/i18n'
import { TranslateResult } from 'vue-i18n'
import { CountryPhoneCodes } from '@/data/CountryPhoneCodes'
import { Utils } from '@/static/Utils'
import { staticValues } from '@/static/StaticValues'
import CountryEnum from '@/enums/CountryEnum'

const warningNotice: Array<TranslateResult> = [
  i18n.t('sheet.notice.precaution_first'),
  i18n.t('sheet.notice.precaution_second'),
  i18n.t('sheet.notice.precaution_third'),
  i18n.t('sheet.notice.precaution_fourth'),
  i18n.t('sheet.notice.precaution_fifth')
]

export const getNoticeMaps = (countries?: Array<string>) => {
  if (!countries) countries = staticValues.availableCountriesIso
  return countries.map((country, index) => {
    const countryTranslated = i18n.t(`country.${country.toLowerCase()}`) as string
    const isTranslated = !countryTranslated.includes('.')
    const otherCountry = CountryPhoneCodes.find(countryPhone => countryPhone.iso === country.toUpperCase())
    const otherCountryName = Utils.isKoreanLocale ? otherCountry?.name_korean : otherCountry?.name
    const countryName = isTranslated ? countryTranslated : otherCountryName || country.toUpperCase()
    const receivableCurrencies = staticValues.receivableCountryCurrencies[country]
    const currency = receivableCurrencies ? receivableCurrencies.join(', ') : 'USD'
    const noticeRowMap: Map<string, string | number> = new Map<string, string | number>()
    noticeRowMap.set(i18n.t('sheet.notice.possible_country') as string, countryName)
    noticeRowMap.set(i18n.t('sheet.notice.country_iso') as string, country.toUpperCase())
    noticeRowMap.set(
      i18n.t('sheet.field.country_number') as string,
      CountryEnum[country as keyof typeof CountryEnum] || ''
    )
    noticeRowMap.set(i18n.t('sheet.notice.possible_currency_iso') as string, currency)
    if (index < warningNotice.length + 1) {
      noticeRowMap.set(' ', ' ')
      noticeRowMap.set(i18n.t('sheet.notice.precaution') as string, warningNotice[index] as string)
    }
    return Object.fromEntries(noticeRowMap)
  })
}
