export const recipientFieldOrder: Array<string> = [
  'CORPS_ID',
  'NAME_FIRST',
  'NAME_MIDDLE',
  'NAME_LAST',
  'NATION',
  'BIRTH_DATE',
  'PHONE_CODE',
  'PHONE',
  'ID_CARD_TYPE',
  'ID_CARD_NUMBER',
  'ADDRESS_POSTAL',
  'ADDRESS_CITY',
  'ADDRESS_LINE1',
  'MASTER_CODE',
  'ACCOUNT_TYPE',
  'IFSC',
  'ACCOUNT_NUMBER',
  'IBAN',
  'BANK_BRANCH',
  'SORT_CODE',
  'TRANSIT_CODE',
  'BSB_CODE',
  'ROUTING_NUMBER',
  'WINGMONEY_NUMBER',
  'CARD_NUMBER',
  'BASE_CURRENCY'
]
