import { IApiRequest } from '@/gateway/axios/IApiRequest'
import { sentbizApi } from '@/gateway/axios'
import CountryEnum from '@/enums/CountryEnum'
import { RecipientSkeletonFields } from '@/gateway/recipient/model/RecipientSkeletonFields'

export interface RecipientFieldSkeletonParam {
  countries: Array<keyof typeof CountryEnum | 'ZZ'>
}

export interface GetRecipientFieldSkeleton extends IApiRequest {
  getInstance (): GetRecipientFieldSkeleton;

  request (data: RecipientFieldSkeletonParam): Promise<Array<RecipientSkeletonFields>>;
}

export class GetRecipientFieldSkeleton implements GetRecipientFieldSkeleton {
  private static instance: GetRecipientFieldSkeleton
  private constructor () {}
  public static getInstance (): GetRecipientFieldSkeleton {
    if (!this.instance) this.instance = new GetRecipientFieldSkeleton()
    return this.instance
  }

  async request (data: RecipientFieldSkeletonParam): Promise<Array<RecipientSkeletonFields>> {
    const requestOption: RequestOption = {
      url: 'remittance-method/skeleton',
      method: 'post',
      data
    }
    const response = await sentbizApi.request(requestOption)
    return response.data.list
  }
}
